import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

interface Props {
  children: React.ReactNode;
}

const DefaultQueryClientProvider = (props: Props) => {
  // queryClient for react-query
  // https://react-query.tanstack.com/quick-start
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  const [showFlower, setShowFlower] = React.useState(false);

  const handleMouseMove = (e: MouseEvent) => {
    if (e.clientX < 100 && window.innerHeight - e.clientY < 100) {
      setShowFlower((_) => true);
    }
    if (window.innerHeight - e.clientY > 500) {
      setShowFlower((_) => false);
    }
  };

  window.onmousemove = handleMouseMove;

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {showFlower && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default DefaultQueryClientProvider;
